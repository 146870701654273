.container{
    height: 54px;
    display: flex;
    align-items: center;
    align-self: stretch;
    position: relative;
}

.connect-wallet{
    border-radius: var(--small-border-radius);
    background: var(--accent-color);
    font-weight: bold;
    font-size: var(--large-title-size);
    padding-left: 16px;
    padding-right: 16px;
    height: 48px;
    text-align: center;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 0;
    cursor: pointer;
}

.logo{
    width: 54px;
    height: 54px;
}

.logo-name-container{
    align-items: center;
    display: flex;
}


.friendly-path-name::before{
    content: '» ';
    font-weight: bold;
    margin-left: 8px;
}

.my-address{
    margin-right: 8px;
}

.wallet-details-container{
    position: absolute;
    right: 0;
    top: 50px;
}

@media only screen and (max-width: 600px){
    .my-address {
        font-size: 10pt;
    }
}
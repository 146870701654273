.sections{
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
}

.section{
    width: 340px;
    height: 200px;
    display: flex;
    flex-direction: column;
    margin: 12px;
    background-color: var(--item-background-color);
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
    border-radius: var(--small-border-radius);
}

.section-button{
    border: none;
    font-size: smaller;
    letter-spacing: 0.1em;
    padding-left: 4px;
    padding-right: 4px;
    font-weight: bold;
    background-color: var(--accent-color);
    border-radius: var(--small-border-radius);
    box-shadow: 0px 2px 2px #000000cc;
}

.disabled{
    pointer-events: none;
    opacity: 0.3;
}

@media only screen and (max-width: 600px){
    .container{
        padding-left: 24px;
        padding-right: 24px;
    }
}


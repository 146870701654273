.container{
    height: 32px;
    background-color: var(--item-background-color);
    border-radius: var(--large-border-radius);
}

.progress{
    background-color: var(--accent-color);
    border-radius: var(--large-border-radius);
    height: 100%;
}

@keyframes fadein-scale {
    from {
        opacity:0;
        transform: scale(0.8);
    }
    to {
        opacity:1;
        transform: scale(1);
    }
  }
  
@keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}


@keyframes spin{
    to{ transform: rotate(360deg); }
  }
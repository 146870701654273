.page{
    flex: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    position: absolute;
    display: flex;
    transition: background-color 300ms;
    background-color: var(--transparent-dark-gray-color);
}

.alert-page{
    background-color: #88000088;
    transition: background-color 300ms
}

.alert-box{
    min-width: 300px;
    max-width: 480px;
    position: relative;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(6px);
    border: 0.5px solid var(--transparent-gray);
    border-radius: var(--small-border-radius);
    background: #000000aa;
    padding: 16px;
    display: flex;
    flex-direction: column;
    box-shadow: #08080866 0 0 16px;
}

.title{
    text-align: center;
    font-weight: bold;
    padding-top: 8px;
    padding-bottom: 20px;
    font-size: 16px;
    pointer-events: none;
    -webkit-user-select: none;
    user-select: none;
    border-bottom: 1px solid #ffffff22;
    margin-bottom: 20px;
}

.description{
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 24px;
    text-align: center;
    pointer-events: none;
    user-select: none;
}

.buttons{
    display: flex;
    padding-left: 24px;
    padding-right: 24px;
    align-items: center;
    justify-content: space-evenly;
}

.button{
    height: 42px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 16px;
    padding-right: 16px;
    margin-left: 10px;
    margin-right: 10px;
    justify-content: center;
    cursor: pointer;
    border-right: none;
    border-left: none;
    border-bottom: none;
    max-width: unset;
    border-top: 0.5px solid var(--transparent-gray);
    background-color: var(--accent-color);
}

.button-text{
    font-weight: bold;
    font-family: var(--button-font);
    letter-spacing: 0.12em;
}

.danger-title{
    color: var(--danger-color);
}

.input-container{
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 12px;
    display: flex;
    align-items: stretch;
    justify-content: stretch;
}

.input-container input{
    padding-left: 24px;
    padding-right: 24px;
    flex-grow: 1;
}
.container{
    display: flex;
    align-items: center;
    justify-content: stretch;
    flex: 1;
    flex-direction: column;
}

.inner-container{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: stretch;
    align-self: center;
    max-width: 1024px;
}

.mint-options{
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.mint-value-select{
    font-size: large;
    border: 2px solid #ccc;
    color: white;
    -webkit-appearance: none;
    padding-left: 12px;
    margin-bottom: 20px;
    padding-top: 6px;
    padding-bottom: 8px;
    -moz-appearance: none;
    appearance: none;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='white'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
    background-size: 30px;
    background-position: calc(100% - 24px) 33px;
    background-repeat: no-repeat;
    border-color: var(--accent-color);
    border-width: 4px;
    width: 100%;
    max-width: 270px;
    height: 92px;
    border-radius: 16px;
    font-size: 36pt;
    font-weight: bold;
}

.nfts{
    flex:1;
    min-width: 0;
    margin-right: 24px;
}

.mint-button{
    height: 92px;
    width: 100%;
    max-width: 270px;
    font-size: 28pt;
    background-color: var(--accent-color);
    border-width: 3px;
    border-radius: 16px;
}

.mint-price{
    font-size: 16pt;
    margin-top: 24px;
    margin-bottom: 16px;
}

.mint-progress-container{
    display: flex;
    align-self: stretch;
    align-items: stretch;
    justify-content: center;
    padding-left: 10vw;
    padding-right: 10vw;
    flex-direction: column;
}

@media only screen and (max-width: 800px){
    .nfts{
        max-height: 70vw;
        margin-bottom: 24px;
        margin-left: 0;
        margin-right: 0;
    }
}
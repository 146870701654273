.container{
    width: 28px;
    height: 28px;
    border-radius: 14px;
    background-color: #181818;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    padding-left: 0;
    padding-right: 0;
    
}

.inner{
    position: relative;
    left: -1px;
    top: -2px;
    font-family: serif;
    transform: rotate(-45deg);
    font-weight: bold;
}
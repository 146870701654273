.container{
    background-color: var(--transparent-dark-gray-color);
    backdrop-filter: var(--backdrop-blur);
    width: 270px;
    min-height: 120px;
    padding: 20px;
    align-items: stretch;
    display: flex;
    flex-direction: column;
    border-radius: var(--small-border-radius);
}
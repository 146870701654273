.container{
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex: 1;
}

iframe{
    flex: 1;
    border: none;
}
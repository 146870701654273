
body{
    margin: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    background-color: var(--page-background-color);
    color: white;
    letter-spacing: 0.03em;
    display: flex;
    justify-content: center;
    min-width: 450px;
}

#root{
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 320px;
    background-color: black;
    padding-left: 4vw;
    padding-right: 4vw;
    padding-top: 2vh;
    padding-bottom: 2vh;
    align-items: center;
    justify-content: center;
}


a{
	text-decoration: none;
	color: #ddd;
}

select{
    font-size: large;
    border: 2px solid #ccc;
    color: white;
    -webkit-appearance: none;
    padding-left: 12px;
    margin-bottom: 20px;
    padding-top: 6px;
    padding-bottom: 8px;
    -moz-appearance: none;
    appearance: none;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='white'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
    /* background-color: #111; */
    background-size: 12px;
    background-position: calc(100% - 16px) 14px;
    background-repeat: no-repeat;
}

sub{
    color: #888;
    font-style: initial;
    font-family: -apple-system, 'BlinkMacSystemFont', 'Open Sans', 'Arial', sans-serif;
}

sub b{
    color: white;
}

@media screen and (min-width: 720px) {
    #root {
        min-width: 700px;
    }
}

*{
    font-family: -apple-system, 'BlinkMacSystemFont', 'Open Sans', 'Arial', sans-serif;
}

main{
    max-width: 1280px;
    width: 75vw;
    background-color: var(--content-background-color);
    padding-left: 4vw;
    padding-right: 4vw;
    padding-top: 2vh;
    padding-bottom: 2vh;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    flex-grow: 1;
    align-self: stretch;
}

a{
	text-decoration: none;
	color: #ddd;
}


sub{
    color: #888;
    font-style: initial;
}

sub b{
    color: white;
}

.fill{
    flex-grow: 1;
}


input[type=text]{
    padding: 8px;
}

.content{
    flex: 1;
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    align-self: center;
    max-width: 1024px;
}

.link-button{
    padding-bottom: 12px;
    padding-top: 12px;
    padding-left: 16px;
    padding-right: 16px;
    text-align: center;
}


.wallet-connected .sale{
    opacity: 1;
    pointer-events: initial;
}

.minting-in-progress .sale{
    display: none;
}

.minting{
    display: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.minting-in-progress .minting{
    display: flex;
}

.inline-link{
    text-decoration: underline;
    text-decoration-style: dotted;
    text-decoration-thickness: 0.5;
    text-underline-offset: 4px;
    text-decoration-color: #ffffff66;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.spinner{
    width: 62px;
    height: 62px;
    animation: spin 1.4s linear infinite;
}

#minting-text{
    display: inline-block;
    text-align: center;
    font-size: x-large;
    margin-left: 20px;
    position: relative;
    top: -6px;
}

.inventory{
    display: none;
}

.showing-inventory .inventory{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1;
    width: 100vw;
}

.showing-inventory .sale{
    display: none;
}

#minting-detail{
    margin-top: 24px;
}

.nft-image{
    width: 300px;
    height: 300px;
    margin-bottom: 12px;
}

.inventory-flip-list{
    display: flex;
    flex-wrap: wrap;
}

.danger{
    color: red;
}

.nft-entry{
    display: flex;
    flex-direction: column;
    margin: 20px;
}

.spin{
    animation: spin 1.2s linear infinite; 
}

.center{
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 800px){
    .hide-compact{
        display: none;
    }

    .flex-row-compact{
        flex-direction: column;
    }

    main{
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }

    #root{
        padding-left: 0;
        padding-right: 0;
    }

    footer {
        margin-left: 24px;
        margin-right: 24px;
    }
}

.expanded{
    transform: scaleY(-1);
}
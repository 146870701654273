.container{
    padding-left: 12px;
    padding-right: 10px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: #111;
    color: #eee;
    border: 1px solid white;
    border-radius: var(--small-border-radius);
    font-weight: bold;
    letter-spacing: 0.05em;
    cursor: pointer;
}

.pressed{
    transform: translateY(1px);
}
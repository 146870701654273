:root{
    --accent-color: #54215c;
    --large-title-size: 16pt;
    --page-background-color: #111;
    --content-background-color: #000;
    --item-background-color: #0f0f0f;
    --transparent-dark-gray-color:#181818aa;
    --backdrop-blur: blur(8px);
    --small-border-radius: 8px;
    --large-border-radius: 16px;

}
.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 600px;
    align-self: center;
}

.links{
    display: flex;
	align-items: center;
    justify-content: space-evenly;
}

.link{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 400px;
}

.logo{
    width: 32px;
	height: 32px;
}

.link-title{
    font-size: small;
    padding-top: 12px;
    margin: 0;
}

.disclaimer{
    padding-top: 20px;
    font-size: x-small;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.copy-tx-log-button{
    border: none;
    font-size: smaller;
    letter-spacing: 0.1em;
    padding-left: 4px;
    padding-right: 4px;
}